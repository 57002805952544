import React, { FormEvent, useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import ReCAPTCHA from 'react-google-recaptcha';
import logo from '../../assets/tech4humans.png';
import { DataLoginProps } from '../../utils/functions/functionsInterfaces';
import { displayWarning } from '../../utils/functions/messageToast';
import { useAuthContext } from '../../hooks/auth';
import { Select } from '../../components/ui/selects';
import { Input } from '../../components/ui/inputs';
import style from './style.module.scss';
import { ButtonLogin } from '../../components/ui/buttons';

const Login: React.FC = () => {
  const { signIn } = useAuthContext();
  const [instance, setInstance] = useState('');
  const [ambient, setAmbient] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [cacheCleared, setCacheCleared] = useState(false);
  const recaptchaRef = useRef<any>(null);
  const useIsomorphicEffect = useEffect;

  // const keyWebSite = import.meta.env.MODE === "production"
  //   ? import.meta.env.VITE_RECAPTCHA_KEY_PROD
  //   : import.meta.env.VITE_RECAPTCHA_KEY_DEV;

  const keyWebSite = "6Lf0nTEpAAAAAIKEgRudFU1j6wxJAoPKUF3EqDk5"; // temporario

  useIsomorphicEffect(() => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }

    const savedEmail = localStorage.getItem('email');
    const savedInstance = localStorage.getItem('instance');
    const savedAmbient = localStorage.getItem('ambient');

    if (savedEmail) setEmail(savedEmail);
    if (savedInstance) setInstance(savedInstance);
    if (savedAmbient) setAmbient(savedAmbient);
  }, []);

  const clearCacheClient = async () => {
    try {
      const cacheKeys = await caches.keys();

      if (cacheKeys.length > 0) {
        await Promise.all(cacheKeys.map(key => caches.delete(key)));
        setCacheCleared(true);

        if (!window.location.search.includes('reloaded=true')) {
          window.location.replace(window.location.href + '?reloaded=true');
        }
      }
    } catch (error: any) {
      return error
    }
  };

  useIsomorphicEffect(() => {
    if (!cacheCleared) {
      clearCacheClient();
    }

    localStorage.setItem('email', email);
    localStorage.setItem('instance', instance);
    localStorage.setItem('ambient', ambient);
  }, [cacheCleared, email, instance, ambient]);

  const onSubmitWithReCAPTCHA = async (event: FormEvent) => {
    event.preventDefault();

    if (ambient === '') {
      displayWarning('Selecione o ambiente!');
      return;
    }

    if (instance === '') {
      displayWarning('Preencha o domínio da empresa!');
      return;
    }

    if (email === '') {
      displayWarning('Preencha o email!');
      return;
    }

    if (password === '') {
      displayWarning('Preencha a senha!');
      return;
    }

    setLoading(true);

    if (import.meta.env.MODE !== 'production') {
      const dataLoginProps: DataLoginProps = {
        instance,
        email,
        password,
        ambient,
        recaptcha_token: "",
      };
      await signIn(dataLoginProps);
    } else {
      const token = await recaptchaRef.current?.executeAsync();

      if (!token) {
        displayWarning('Por favor, complete o reCAPTCHA!');
        setLoading(false);
        return;
      }

      const dataLoginProps: DataLoginProps = {
        instance,
        email,
        password,
        ambient,
        recaptcha_token: token,
      };

      await signIn(dataLoginProps);
    }

    setLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAmbient(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>Tech Forms - Login</title>
      </Helmet>

      <div className={style.containerCenter}>
        <div className={style.login}>
          <img src={logo} alt="Logo Tech" />

          <form onSubmit={onSubmitWithReCAPTCHA}>
            <div className={style.containerAviso}>
              <h4 className={style.aviso}>Faça o login com usuario de automação</h4>
            </div>

            <Select value={ambient} onChange={handleChange}>
              <option value="" disabled>
                Selecione o ambiente
              </option>
              <option value="prod">Produção</option>
              <option value="hml">Homologação</option>
            </Select>

            <Input
              placeholder="Domínio da empresa"
              type="text"
              value={instance}
              onChange={(e) => setInstance(e.target.value)}
              autoComplete="company-domain"
            />

            <Input
              placeholder="E-mail"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="email"
            />

            <Input
              placeholder="Senha"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />

            <ButtonLogin type="submit" loading={loading}>
              Login
            </ButtonLogin>
          </form>

          {import.meta.env.MODE === 'production' && (
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={keyWebSite}
              size="invisible"
            />
          )}

        </div>
      </div>
    </>
  );
};

export default Login;
