import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/login';
import Home from './pages/home';
import CreateForm from './pages/createForm';
import UpdateForm from './pages/updateForm';
import ListForms from './pages/listForms';
import CreateTemplate from './pages/createTemplate';
import Tabulation from './pages/tabulation';

const MainRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/section" element={<Home />}>
        <Route path="createForm" element={<CreateForm />} />
        <Route path="updateForm" element={<UpdateForm />} />
        <Route path="listForms" element={<ListForms />} />
        <Route path="createTemplate" element={<CreateTemplate />} />
        <Route path="tabulation" element={<Tabulation />} />
      </Route>

    </Routes>
  );
};

export default MainRoutes;
