import { useState, useEffect } from 'react'
import { useAuthContext } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { FiLogOut } from 'react-icons/fi'
import style from './styles.module.scss'
import logoImg from '../../assets/tech4humans.png';

export function Header({ home = false }) {
  const { user, signOut } = useAuthContext()
  const navigate = useNavigate()
  const [formId, setFormId] = useState<string | null>(null)
  const clientName = user?.clientName
  const userName = user?.userName
  const clientId = user?.clientId
  const ambient = user?.ambientLogged

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fromListForms = queryParams.get('formId');
    setFormId(fromListForms);
  }, [clientId, navigate]);

  return (
    <header className={style.headerContainer}>

      <div className={style.headerContent}>

        <div className={style.divName}>
          <Link to='/section'>
            <img src={logoImg}
              width={170}
              height={60}
              alt='Logo Tech' />
          </Link>
          <div className={style.infoCompany}>
            <h5>{clientName}</h5>
          </div>
        </div>

      </div>

      <div className={style.headerContentButtons}>

        <nav className={style.menuNav}>
          <div className={style.info}>
            <h5>{user?.userEmail}</h5>
          </div>
          <button onClick={signOut}>
            <FiLogOut style={{ color: '#fff' }} size={24} />
          </button>
        </nav>

      </div>

    </header>
  )
}