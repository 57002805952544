import { apiGetFormsAndLogin } from '../../services/apiClient'
import React, { useEffect, useState } from 'react'
import style from './style.module.scss'

import {
  FieldProps,
  GroupProps,
} from '../../utils/functions/functionsInterfaces'

import {
  handleAddAttachmentsFieldOutsideGroup,
  handleAddField,
  handleAddFieldAttachments,
  handleAddFieldOutsideGroup,
  handleAddGroup,
  handleAddPersonalDetailField,
  handleAddPersonalDetailFieldOutsideGroup,
  handleCopyGroup,
  handleRemoveField,
  handleRemoveFieldInGroup,
  handleRemoveGroup,
} from '../../utils/functions/functionsFields'

import {
  formatNameAlias,
  publishForm,
  saveForm,
} from '../../utils/functions/functionsForms'
import {
  getFormSideProps,
  getSkillId,
  getTabId,
  toggleGroupVisibility,
  generatePayload
} from '../../utils/functions/functionsLoadDataForm'

import {
  handleFormSubmit,
  handleInputAliasChange,
  handleInputChange,
  handleInputIdChange,
  handleInputItemIdChange,
  handleInputNameChange,
  handleInputSkillChange,
  handleInputTabChange,
} from '../../utils/functions/functionsInputs'

import { SelectFieldPersonalDetails } from '../../components/ui/selects'
import { useAuthContext } from '../../hooks/auth'
import { Helmet } from 'react-helmet-async';
import { DragDropContext } from '@hello-pangea/dnd'
import { generateJSON } from '../../utils/functions/functionGenerateJson'
import { useLocation } from 'react-router-dom'
import FormFields from '../../components/FormFields'
import { ButtonAddAttachmentsFieldOutsideGroup, ButtonAddFieldOutsideGroup, ButtonAddGroup, ButtonClear, ButtonGenerateJson, ButtonGeneratePayload, ButtonPublishForm, ButtonSaveForm, ButtonSearchForm } from '../../components/ui/buttons'
import { InputsInfosPages, InputSkill, InputTab } from '../../components/ui/inputs'

export default function UpdateForm() {

  const { authorization, authorizationRA, user } = useAuthContext()

  const clientId = user?.clientId as string
  const ambient = user?.ambient as string

  const [form, setForm] = useState<any[]>([])
  const [groups, setGroups] = useState<GroupProps[]>([])
  const [fields, setFields] = useState<FieldProps[]>([])
  const [search, setSearch] = useState('')
  const [showTopContainer, setShowTopContainer] = useState(false)
  const [expandedGroups, setExpandedGroups] = useState<string[]>([])
  const [showTopPageContainer, setShowTopPageContainer] = useState(false)
  const [formId, setFormId] = useState('')
  const [nameForm, setNameForm] = useState('')
  const [skillIdForm, setSkillIdForm] = useState('')
  const [skillName, setSkillName] = useState('')
  const [tabIdForm, setTabIdForm] = useState('')
  const [tabName, setTabName] = useState('')
  const [itemId, setItemId] = useState('')
  const [aliasFormResult, setAliasFormResult] = useState('')
  const [checkTheFunction, setCheckTheFunction] = useState(true)
  const [idFormSaved, setIdFormSaved] = useState('')
  const [buttonSave, setButtonSave] = useState(false)
  const [buttonPublish, setButtonPublish] = useState(false)
  const [addFieldPersonalDetails, setAddFieldPersonalDetails] = useState('')
  const [loadingButtonSave, setLoadingButtonSave] = useState(false);
  const [loadingButtonPublish, setLoadingButtonPublish] = useState(false);
  const [loadingButtonGeneratePayload, setLoadingButtonGeneratePayload] = useState(false);
  const [searchFormId, setSearchFormId] = useState('');
  const [isData, setIsData] = useState(false)
  const [pageId, setPageId] = useState('');
  const [namePage, setNamePage] = useState(nameForm)
  const [isCreated, setIsCreated] = useState(false)
  const [expandedPage, setExpandedPage] = useState<string[]>([pageId])

  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fromListForms = queryParams.get('formId');
    setSearchFormId(fromListForms || '');

    if (searchFormId) {
      getFormSideProps(
        setExpandedPage,
        setShowTopPageContainer,
        setNamePage,
        setPageId,
        setIsData,
        searchFormId,
        setFormId,
        setNameForm,
        setSkillIdForm,
        setTabIdForm,
        setItemId,
        setGroups,
        setFields,
        setShowTopContainer,
        setButtonSave,
        setButtonPublish,
        clientId,
        apiGetFormsAndLogin,
        setAliasFormResult,
        setForm,
        ambient,
        setSkillName,
        authorization,
        setTabName
      );
    }
  }, [location, searchFormId, ambient, clientId, authorization, authorizationRA])

  const generateJson = async () => {
    const json = generateJSON(
      undefined,
      isCreated,
      pageId,
      formId,
      nameForm,
      skillIdForm,
      tabIdForm,
      aliasFormResult,
      form
    )
    console.log(json)
  }

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    setButtonSave(true)
    return result
  }

  const onDragEnd = (result: any) => {
    const { source, destination } = result
    if (!destination) {
      return
    }
    const items = reorder(form, source.index, destination.index)
    setButtonSave(true)
    setForm(items)
  }



  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>

        <Helmet>
          <title>Tech Forms - Atualizar Formulário</title>
        </Helmet>

        <div className={style.container}>

          <form
            className={style.searchForm}
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              handleFormSubmit(
                setExpandedPage,
                setShowTopPageContainer,
                setNamePage,
                setPageId,
                setIsData,
                event,
                search,
                setFormId,
                setNameForm,
                setSkillIdForm,
                setTabIdForm,
                setItemId,
                setGroups,
                setFields,
                setShowTopContainer,
                setButtonSave,
                setButtonPublish,
                clientId,
                apiGetFormsAndLogin,
                setAliasFormResult,
                setForm,
                ambient,
                setSkillName,
                authorization,
                setTabName
              )
            }}
          >
            <InputsInfosPages
              placeholder='Buscar formulário pelo alias'
              type='text'
              value={search}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputChange(event, setSearch)
              }
            />

            <ButtonSearchForm
              onClick={() => {
                getFormSideProps(
                  setExpandedPage,
                  setShowTopPageContainer,
                  setNamePage,
                  setPageId,
                  setIsData,
                  search,
                  setFormId,
                  setNameForm,
                  setSkillIdForm,
                  setTabIdForm,
                  setItemId,
                  setGroups,
                  setFields,
                  setShowTopContainer,
                  setButtonSave,
                  setButtonPublish,
                  clientId,
                  apiGetFormsAndLogin,
                  setAliasFormResult,
                  setForm,
                  ambient,
                  setSkillName,
                  authorization,
                  setTabName
                )
              }}
            />

            <ButtonClear
              onClick={() => {
                const queryParams = new URLSearchParams(location.search);
                const fromListForms = queryParams.get('formId');
                if (fromListForms) {
                  queryParams.delete('formId');
                  window.location.href
                    = `${location.pathname}?${queryParams.toString()}`;
                }
                setSearch(''),
                  setShowTopContainer(false),
                  setShowTopPageContainer(false),
                  setGroups([]),
                  setFields([]),
                  setForm([])
              }}
            />

          </form>

          {showTopContainer && (
            <div>
              <div className={style.tabSkill}>
                <div className={style.inputsHeader}>

                  <div className={style.containerRowObject}>
                    {/* <h5 className={style.h5hidden}>Id</h5> */}
                    <div className={style.rowObjects}>
                      <p className={style.titles}>Id</p>
                      <InputsInfosPages
                        placeholder='Insira o Id do formulário'
                        type='text'
                        value={formId}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleInputIdChange(event, setFormId)
                        }
                        disabled={true}
                        style={{ cursor: 'not-allowed' }}
                      />
                    </div>
                  </div>

                  <div className={style.containerRowObject}>
                    {/* <h5 className={style.h5hidden}>ItemId</h5> */}
                    <div className={style.rowObjects}>
                      <p className={style.titles}>ItemId</p>
                      <InputsInfosPages
                        placeholder='Insira o ItemId'
                        type='text'
                        value={itemId}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleInputItemIdChange(event, setItemId)
                        }
                        disabled={true}
                        style={{ cursor: 'not-allowed' }}
                      />
                    </div>
                  </div>

                  <div className={style.containerRowObject}>
                    {/* <h5 className={style.h5hidden}>Nome</h5> */}
                    <div className={style.rowObjects}>
                      <p className={style.titles}>Nome</p>
                      <InputsInfosPages
                        placeholder='Insira o nome do formulário'
                        type='text'
                        value={nameForm}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleInputNameChange(event, setNameForm)
                        }
                        onBlur={(event) => {
                          formatNameAlias(nameForm, setAliasFormResult)
                        }}
                      />
                    </div>
                  </div>

                  <div className={style.containerRowObject}>
                    {/* <h5 className={`${!skillName ? style.h5hiddenClear : ''
                      }`}>{skillName}</h5> */}
                    <div className={style.rowObjects}>
                      <p className={style.titles}>SkillId</p>
                      <InputSkill
                        placeholder='Insira a skillId'
                        type='text'
                        skillName={skillName}
                        value={skillIdForm}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleInputSkillChange(event, setSkillIdForm)
                        }
                        onBlur={() => getSkillId(skillIdForm, authorization, ambient, setSkillName)}
                      />
                    </div>
                  </div>

                  <div className={style.containerRowObject}>
                    {/* <h5 className={style.h5hidden}>Alias</h5> */}
                    <div className={style.rowObjects}>
                      <p className={style.titles}>Alias</p>
                      <InputsInfosPages
                        placeholder='Insira o alias'
                        type='text'
                        value={aliasFormResult}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleInputAliasChange(event, setAliasFormResult)
                        }
                      />
                    </div>
                  </div>

                  <div className={style.containerRowObject}>
                    {/* <h5 className={`${!tabName ? style.h5hiddenClear : ''
                      }`}>{tabName}</h5> */}
                    <div className={style.rowObjects}>
                      <p className={style.titles}>TabId</p>
                      <InputTab
                        placeholder='Insira a tabId'
                        type='text'
                        tabName={tabName}
                        value={tabIdForm}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleInputTabChange(event, setTabIdForm)
                        }
                        onBlur={() => getTabId(tabIdForm, authorization, ambient, setTabName)}
                      />
                    </div>
                  </div>

                </div>

                <div className={style.topContainer}>

                  <ButtonAddGroup onClick={() => handleAddGroup(setForm, setButtonSave)} />

                  <ButtonAddFieldOutsideGroup onClick={() => handleAddFieldOutsideGroup(setForm, setButtonSave)} />

                  <ButtonAddAttachmentsFieldOutsideGroup onClick={() => handleAddAttachmentsFieldOutsideGroup(setForm, setButtonSave)} />

                  <SelectFieldPersonalDetails
                    value={addFieldPersonalDetails}
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      const newValue = event.target.value
                      setAddFieldPersonalDetails(newValue)
                      handleAddPersonalDetailFieldOutsideGroup(
                        form,
                        setForm,
                        newValue,
                        setAddFieldPersonalDetails,
                        setButtonSave
                      )
                    }}
                  />

                  {import.meta.env.MODE !== 'production' && (
                    <ButtonGenerateJson onClick={generateJson} />
                  )}

                  <ButtonSaveForm
                    loading={loadingButtonSave}
                    buttonSave={buttonSave}
                    onClick={() =>
                      saveForm(
                        setLoadingButtonSave,
                        generateJSON(
                          undefined,
                          isCreated,
                          pageId,
                          formId,
                          nameForm,
                          skillIdForm,
                          tabIdForm,
                          aliasFormResult,
                          form
                        ),
                        authorization,
                        authorizationRA,
                        setButtonPublish,
                        setButtonSave,
                        setIdFormSaved,
                        nameForm,
                        skillIdForm,
                        tabIdForm,
                        aliasFormResult,
                        ambient,
                        setTabName,
                        setSkillName
                      )
                    } />

                  <ButtonPublishForm
                    loading={loadingButtonPublish}
                    buttonPublish={buttonPublish}
                    onClick={() => publishForm(
                      setExpandedPage,
                      setShowTopPageContainer,
                      setNamePage,
                      setPageId,
                      setIsData,
                      setLoadingButtonPublish,
                      idFormSaved,
                      setSearch,
                      setFormId,
                      setNameForm,
                      setSkillIdForm,
                      setTabIdForm,
                      setItemId,
                      setGroups,
                      setFields,
                      setShowTopContainer,
                      setButtonSave,
                      setButtonPublish,
                      clientId,
                      apiGetFormsAndLogin,
                      authorizationRA,
                      aliasFormResult,
                      setAliasFormResult,
                      setForm,
                      checkTheFunction,
                      ambient,
                      setTabName,
                      setSkillName,
                      authorization
                    )}
                  />

                  <ButtonGeneratePayload
                    loading={loadingButtonGeneratePayload}
                    onClick={() =>
                      generatePayload(
                        setLoadingButtonGeneratePayload,
                        clientId,
                        authorization,
                        ambient,
                        formId
                      )}
                  />

                </div>
              </div>
            </div>
          )}

          <FormFields
            form={form}
            setForm={setForm}
            groups={groups}
            expandedGroups={expandedGroups}
            setExpandedGroups={setExpandedGroups}
            handleCopyGroup={handleCopyGroup}
            handleRemoveGroup={handleRemoveGroup}
            handleAddField={handleAddField}
            handleAddFieldAttachments={handleAddFieldAttachments}
            handleAddPersonalDetailField={handleAddPersonalDetailField}
            handleRemoveField={handleRemoveField}
            handleRemoveFieldInGroup={handleRemoveFieldInGroup}
            toggleGroupVisibility={toggleGroupVisibility}
            setAddFieldPersonalDetails={setAddFieldPersonalDetails}
            addFieldPersonalDetails={addFieldPersonalDetails}
            pageId={pageId}
            setPageId={setPageId}
            namePage={namePage}
            setNamePage={setNamePage}
            isCreated={isCreated}
            setIsCreated={setIsCreated}
            expandedPage={expandedPage}
            setExpandedPage={setExpandedPage}
            showTopPageContainer={showTopPageContainer}
            setButtonSave={setButtonSave}
            authorization={authorization}
            clientId={clientId}
            ambient={ambient}
            formId={formId}
          />

        </div>
      </div>
    </DragDropContext>
  )
}