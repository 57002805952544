import {
  FieldProps,
  GroupProps,
  AllForms,
  arrayCSV,
} from "./functionsInterfaces";
import { formatNameAlias } from "./functionsForms";
import {
  setupApiGetAllForms,
  setupApiGetTabAndSkill,
  setupApiTabulateTickets,
} from "../../services/setupApis";
import {
  displayError,
  displayInfo,
  displaySuccess,
  displayWarning,
} from "./messageToast";
import { v4 as uuidv4 } from "uuid";

export const getFormSideProps = async (
  setExpandedPages: React.Dispatch<React.SetStateAction<string[]>>,
  setShowTopPageContainer: React.Dispatch<React.SetStateAction<boolean>>,
  setNamePage: React.Dispatch<React.SetStateAction<string>>,
  setPageId: React.Dispatch<React.SetStateAction<string>>,
  setIsData: React.Dispatch<React.SetStateAction<boolean>>,
  search: string,
  setFormId: React.Dispatch<React.SetStateAction<string>>,
  setNameForm: React.Dispatch<React.SetStateAction<string>>,
  setSkillIdForm: React.Dispatch<React.SetStateAction<string>>,
  setTabIdForm: React.Dispatch<React.SetStateAction<string>>,
  setItemId: React.Dispatch<React.SetStateAction<string>>,
  setGroups: React.Dispatch<React.SetStateAction<GroupProps[]>>,
  setFields: React.Dispatch<React.SetStateAction<FieldProps[]>>,
  setShowTopContainer: React.Dispatch<React.SetStateAction<boolean>>,
  setButtonSave: React.Dispatch<React.SetStateAction<boolean>>,
  setButtonPublish: React.Dispatch<React.SetStateAction<boolean>>,
  clientId: string,
  apiGetFormsAndLogin: any,
  setAliasFormResult: React.Dispatch<React.SetStateAction<string>>,
  setForm: React.Dispatch<React.SetStateAction<any[]>>,
  ambient: string,
  setSkillName: React.Dispatch<React.SetStateAction<string>>,
  authorization: string,
  setTabName: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    if (search.trim() === "") {
      displayWarning("Digite um alias para buscar o formulário!");
      return;
    } else {
      if (!clientId) {
        displayWarning("Preencha o clientId!");
        return;
      }
      const response = await apiGetFormsAndLogin.post(`/get-form`, {
        clientId,
        alias: search,
        ambient,
      });
      setFormId(response.data.id);
      setNameForm(response.data.name);
      // setAliasFormResult(search);
      await formatNameAlias(response.data.name, setAliasFormResult);
      setSkillIdForm(response.data.skillId);
      setTabIdForm(response.data.tabId);
      setItemId(response.data.itemId);
      let form;

      if (response.data.children[0].type === "page") {
        setIsData(true);
        setPageId(response.data.children[0].id);
        setNamePage(response.data.children[0].name);
        form = response.data.children[0].children;
      } else {
        setIsData(false);
        form = response.data.children;
      }

      getSkillId(response.data.skillId, authorization, ambient, setSkillName);
      getTabId(response.data.tabId, authorization, ambient, setTabName);

      // add uuid em cada objeto do form
      form = form.map((field: any) => {
        field.uuid = uuidv4();
        if (field.type === "group") {
          field.children = field.children.map((field: any) => {
            field.uuid = uuidv4();
            return field;
          });
        }
        return field;
      });
      setForm(form);

      setShowTopContainer(true);
      setShowTopPageContainer(true);
      // setButtonSave(true);
      setButtonPublish(false);
      setExpandedPages([response.data.children[0].id]);
    }
  } catch (error: any) {
    if (error.response.status === 404) {
      setShowTopContainer(false);
      setGroups([]);
      setFields([]);
      setForm([]);
      displayWarning("Formulário não encontrado!");
    } else if (error.response.status === 401) {
      setShowTopContainer(false);
      setGroups([]);
      setFields([]);
      setForm([]);
      displayWarning("Você não tem permissão para acessar este formulário!");
    } else {
      displayError("Erro ao buscar formulário!");
    }
  }
};

export const toggleGroupVisibility = (
  groupId: string,
  expandedGroups: string[],
  setExpandedGroups: React.Dispatch<React.SetStateAction<string[]>>
) => {
  if (expandedGroups.includes(groupId)) {
    setExpandedGroups(expandedGroups.filter((id) => id !== groupId));
  } else {
    setExpandedGroups([...expandedGroups, groupId]);
  }
};

export const getSkillId = async (
  skillIdForm: string,
  authorization: string,
  ambient: string,
  setSkillName: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    if (skillIdForm === "") {
      return;
    }
    const apiClient = setupApiGetTabAndSkill(undefined, authorization);
    const response = await apiClient.get(
      `/get-skill?skillId=${skillIdForm}&ambient=${ambient}`
    );
    setSkillName(response.data.name);
    return response.data;
  } catch (error: any) {
    displayError("Habilidade não encontrada!");
    return;
  }
};

export const getTabId = async (
  tabIdForm: string,
  authorization: string,
  ambient: string,
  setTabName: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    if (tabIdForm === "") {
      return;
    }
    const apiClient = setupApiGetTabAndSkill(undefined, authorization);
    const response = await apiClient.get(
      `/get-tab?tabId=${tabIdForm}&ambient=${ambient}`
    );
    setTabName(response.data.name);
    return response.data;
  } catch (error: any) {
    displayError("Tabulação não encontrada!");
    return;
  }
};

export const getAllForms = async (
  setLoadingPage: React.Dispatch<React.SetStateAction<boolean>>,
  authorization: string,
  ambient: string,
  setAllForm: React.Dispatch<React.SetStateAction<AllForms[]>>,
  currentPage: number,
  itemsPerPage: number,
  setCountForms: React.Dispatch<React.SetStateAction<number>>
) => {
  try {
    setLoadingPage(true);
    const apiClient = setupApiGetAllForms(undefined, authorization);
    const response = await apiClient.get(
      `/get-all-forms?ambient=${ambient}&page=${currentPage}&itemsPerPage=${itemsPerPage}`
    );
    setAllForm(response.data.mapForms);
    setCountForms(response.data.count);
    setLoadingPage(false);
    return response.data;
  } catch (error: any) {
    displayError("Erro ao buscar formulários!");
    return;
  } finally {
    setLoadingPage(false);
  }
};

export const generatePayload = async (
  setLoadingButtonGeneratePayload: React.Dispatch<
    React.SetStateAction<boolean>
  >,
  clientId: string,
  authorization: string,
  ambient: string,
  alias: string
) => {
  try {
    setLoadingButtonGeneratePayload(true);
    const apiClient = setupApiGetAllForms(undefined, authorization);
    const response = await apiClient.post(
      `/create-payload?alias=${alias}&clientId=${clientId}&ambient=${ambient}`
    );
    navigator.clipboard.writeText(
      JSON.stringify(response.data.payload, null, 2)
    );
    displaySuccess("Payload copiado para a área de transferência!");
    setLoadingButtonGeneratePayload(false);
    return response.data.payload;
  } catch (error: any) {
    displayError("Erro ao gerar payload!");
    return;
  }
};

export const getPathField = async (
  targetField: string,
  clientId: string,
  ambient: string,
  formId: string,
  authorization: string,
  groupField: string
) => {
  try {
    const apiClient = setupApiGetAllForms(undefined, authorization);
    const getPayload = await apiClient.post(
      `/create-payload?alias=${formId}&clientId=${clientId}&ambient=${ambient}`
    );
    const getPath = await apiClient.post(
      `/take-the-path-field?targetField=${targetField}&targetGroupField=${groupField}`,
      getPayload.data.payload
    );
    navigator.clipboard.writeText(
      JSON.stringify(getPath.data.pathField, null, 2).replace(/"/g, "")
    );
    displayInfo(`${getPath.data.pathField}`, 1000);
    return getPath.data.pathField;
  } catch (error: any) {
    displayError("Erro ao gerar caminho!");
    return;
  }
};

export const tabulateTickets = async (
  tabId: string,
  ambient: string,
  authorization: string,
  arrayCsv: arrayCSV[],
  setCsvData: React.Dispatch<React.SetStateAction<any[]>>,
  setTabId: React.Dispatch<React.SetStateAction<string>>
) => {
  try {
    const apiClient = setupApiTabulateTickets(undefined, authorization);
    await apiClient.post(
      `/tabulation-ticket?ambient=${ambient}&tabId=${tabId}`,
      arrayCsv
    );

    displaySuccess("Tickets tabulados com sucesso!");

    setCsvData([]);
    setTabId("");
    return;
  } catch (error: any) {
    displayError("Erro ao gerar caminho!");
    return;
  }
};
