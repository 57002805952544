import { Link, Outlet, useLocation } from 'react-router-dom';
import style from './style.module.scss';
import { Helmet } from 'react-helmet-async';
import { Header } from '../../components/Header';
import { useAuthContext } from '../../hooks/auth';

export default function Home() {
  const { pathname } = useLocation();
  const { user } = useAuthContext();
  const clientId = user?.clientId as string;

  const webAppEsteiras = [
    { href: "listForms", label: 'Listar Formulários' },
    { href: "createForm", label: 'Criar novo formulário' },
    { href: "updateForm", label: 'Atualizar formulário' },
    { href: "createTemplate", label: 'Criar form pelo payload' },
    { href: "tabulation", label: 'Tabulação em massa' },
  ];

  return (
    <>
      <Helmet>
        <title>Tech Forms - Tabulação em massa</title>
      </Helmet>

      <Header />
      <div className={style.mainContainer}>
        <aside className={style.sectionContainer}>
          {webAppEsteiras.map((button) => (
            <Link to={button.href} key={button.label}>
              <button
                className={`${style.button} ${pathname === button.href ? style.buttonActive : ''}`}
              >
                {button.label}
              </button>
            </Link>
          ))}
        </aside>

        {/* Renderiza o conteúdo da rota filha aqui */}
        <main className={style.contentContainer}>
          <Outlet />
        </main>
      </div>
    </>
  );
}
// import { useAuthContext } from '../../hooks/auth'
// import { Helmet } from 'react-helmet-async';
// import { Link } from 'react-router-dom';
// import { Header } from '../../components/Header'
// import style from './style.module.scss'
// import { useEffect, useState } from 'react';

// export default function Home() {
//   const { user } = useAuthContext()
//   const clientId = user?.clientId as string
//   const [cacheCleared, setCacheCleared] = useState(false)

//   const useIsomorphicEffect = useEffect

//   const clearCacheClient = async () => {
//     try {
//       const cacheKeys = await caches.keys()

//       if (cacheKeys.length > 0) {
//         await Promise.all(cacheKeys.map(key => caches.delete(key)))

//         setCacheCleared(true)

//         if (!window.location.search.includes("reloaded=true")) {
//           window.location.replace(window.location.href + "?reloaded=true")
//         }
//       }
//     } catch (error) {
//       return error
//     }
//   }

//   useIsomorphicEffect(() => {
//     if (!cacheCleared) {
//       clearCacheClient()
//     }
//   }, [cacheCleared])

//   return (
//     <div>
//       <Helmet>
//         <title>Tech Forms - Home</title>
//       </Helmet>
//       <Header home />

//       <div className={style.container}>
//         <div className={style.topContainer}>
//           <Link to={`/createForm?clientId=${clientId}`}>
//             <button className={style.button}>
//               Criar novo formulário
//             </button>
//           </Link>

//           <Link to={`/updateForm?clientId=${clientId}`}>
//             <button className={style.button}>
//               Atualizar formulário
//             </button>
//           </Link>

//           <Link to={`/listForms?clientId=${clientId}`}>
//             <button className={style.button}>
//               Lista de Formulários
//             </button>
//           </Link>

//           <Link to={`/createTemplate?clientId=${clientId}`}>
//             <button className={style.button}>
//               Criar form pelo payload
//             </button>
//           </Link>

//           <Link to={`/tabulation?clientId=${clientId}`}>
//             <button className={style.button}>
//               Tabulação em massa
//             </button>
//           </Link>

//           {import.meta.env.MODE !== 'production' && (
//             <Link to={`/section?clientId=${clientId}`}>
//               <button className={style.button}>
//                 Testes
//               </button>
//             </Link>
//           )}

//         </div>
//       </div>
//     </div>
//   )
// }