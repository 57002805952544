import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Helmet } from 'react-helmet-async';
import style from './style.module.scss';
import { getAllForms } from '../../utils/functions/functionsLoadDataForm';

export default function ListForms() {
  const navigate = useNavigate();
  const { authorizationRA, user } = useAuthContext();
  const ambient = user?.ambient as string;
  const clientId = user?.clientId as string;
  const [allForm, setAllForm] = useState<any[]>([]);
  const [countForms, setCountForms] = useState(0);
  const [loadingPage, setLoadingPage] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  useEffect(() => {
    setLoadingPage(true);
    getAllForms(setLoadingPage, authorizationRA, ambient, setAllForm, currentPage, itemsPerPage, setCountForms);
  }, [authorizationRA, ambient, currentPage, itemsPerPage]);

  const handleFormClick = (formId: string) => {
    navigate(`/section/updateForm?clientId=${clientId}&formId=${formId}`, { state: { formId } });
  };

  const handleNextPage = () => {
    if (currentPage < Math.ceil(countForms / itemsPerPage)) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Tech Forms - Listagem dos Formulários</title>
      </Helmet>

      <div className={style.container}>
        {loadingPage ? (
          <div className={style.spinnerContainer}>
            <FontAwesomeIcon icon={faSpinner} size="2x" style={{ color: "#ffffff" }} spin />
          </div>
        ) : (
          <>
            <div className={style.pagination}>
              <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                <FontAwesomeIcon icon={faArrowLeft} size="2x" />
              </button>
              <span>Página {currentPage} de {Math.ceil(countForms / itemsPerPage)}</span>
              <button onClick={handleNextPage} disabled={currentPage === Math.ceil(countForms / itemsPerPage)}>
                <FontAwesomeIcon icon={faArrowRight} size="2x" /></button>
            </div>
            <div className={style.main}>
              <div className={style.divForm}>
                {allForm.map((form: any) => (
                  <div key={form.id} onClick={() => handleFormClick(form.id)} className={style.form}>
                    <div className={style.formRow}>
                      <span className={style['item-title']}><span className={style.nameItems}>Id: </span>{form.id}</span>
                      <span className={style['item-title']}><span className={style.nameItems}>Nome: </span>{form.name}</span>
                      <span className={style['item-title']}><span className={style.nameItems}>Criado em: </span>{format(new Date(form.createdAt), 'dd/MM/yyyy HH:mm', { locale: ptBR })}</span>
                    </div>
                    <div className={style.formRow}>
                      <span className={style['item-title']}><span className={style.nameItems}>ItemId: </span>{form.itemId}</span>
                      <span className={style['item-title']}><span className={style.nameItems}>Atualizado em: </span>{format(new Date(form.updatedAt), 'dd/MM/yyyy HH:mm', { locale: ptBR })}</span>
                      <div className={style.divStatus}>
                        <span className={`${form.status === 'published' ? style.published : style.unpublished}`}>
                          {form.status}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
//<span className={style.nameItems}>Criado em: </span>