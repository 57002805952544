import { apiGetFormsAndLogin } from '../../services/apiClient'
import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import { Helmet } from 'react-helmet-async';

import {
  FieldProps,
  GroupProps,
} from '../../utils/functions/functionsInterfaces'

import {
  handleAddAttachmentsFieldOutsideGroup,
  handleAddField,
  handleAddFieldAttachments,
  handleAddFieldOutsideGroup,
  handleAddGroup,
  handleAddPersonalDetailField,
  handleAddPersonalDetailFieldOutsideGroup,
  handleCopyGroup,
  handleRemoveField,
  handleRemoveFieldInGroup,
  handleRemoveGroup,
} from '../../utils/functions/functionsFields'

import {
  formatNameAlias,
  publishForm,
  saveForm,
} from '../../utils/functions/functionsForms'
import {
  getSkillId,
  getTabId,
  toggleGroupVisibility,
} from '../../utils/functions/functionsLoadDataForm'

import {
  handleInputAliasChange,
  handleInputNameChange,
  handleInputSkillChange,
  handleInputTabChange,
} from '../../utils/functions/functionsInputs'

import { SelectFieldPersonalDetails } from '../../components/ui/selects'
import { useAuthContext } from '../../hooks/auth'
import { fieldsPersonalDetails } from '../../utils/functions/fieldsPersonalDetails'
import { DragDropContext } from '@hello-pangea/dnd'
import { generateJSON } from '../../utils/functions/functionGenerateJson'
import FormFields from '../../components/FormFields'
import { InputsInfosPages, InputSkill, InputTab } from '../../components/ui/inputs'
import { ButtonAddAttachmentsFieldOutsideGroup, ButtonAddFieldOutsideGroup, ButtonAddGroup, ButtonGenerateJson, ButtonPublishForm, ButtonSaveForm } from '../../components/ui/buttons'

export default function CreateForm() {
  const personalDetails = fieldsPersonalDetails()

  const { authorizationRA, authorization, user } = useAuthContext()

  const clientId = user?.clientId as string
  const ambient = user?.ambient as string

  const [form, setForm] = useState<any[]>([...personalDetails])
  const [groups, setGroups] = useState<GroupProps[]>([])
  const [fields, setFields] = useState<FieldProps[]>([])
  const [search, setSearch] = useState('')
  const [showTopContainer, setShowTopContainer] = useState(false)
  const [expandedGroups, setExpandedGroups] = useState<string[]>([])
  const [showTopPageContainer, setShowTopPageContainer] = useState(true)
  const [formId, setFormId] = useState('')
  const [nameForm, setNameForm] = useState('')
  const [skillIdForm, setSkillIdForm] = useState('')
  const [skillName, setSkillName] = useState('')
  const [tabIdForm, setTabIdForm] = useState('')
  const [tabName, setTabName] = useState('')
  const [itemId, setItemId] = useState('')
  const [aliasFormResult, setAliasFormResult] = useState('')
  const [checkTheFunction, setCheckTheFunction] = useState(false)
  const [idFormSaved, setIdFormSaved] = useState('')
  const [buttonSave, setButtonSave] = useState(false)
  const [buttonPublish, setButtonPublish] = useState(false)
  const [addFieldPersonalDetails, setAddFieldPersonalDetails] = useState('')
  const [loadingButtonSave, setLoadingButtonSave] = useState(false);
  const [loadingButtonPublish, setLoadingButtonPublish] = useState(false);
  const [isData, setIsData] = useState(false)
  const [pageId, setPageId] = useState('data');
  // const [cacheClient, setCacheClient] = useState<string[]>([])
  const [namePage, setNamePage] = useState(nameForm)
  const [isCreated, setIsCreated] = useState(true)
  const [expandedPage, setExpandedPage] = useState<string[]>([pageId])

  const structureForm = {
    id: pageId,
    name: namePage,
    type: "page",
    children: [...form],
  }

  // const useIsomorphicEffect = typeof window !== 'undefined' ? useEffect : useEffect

  // const clearCacheClient = async () => {
  //   if (cacheClient) cacheClient?.forEach((key) => caches?.delete(key))
  //   window.location.replace(window.location.href + "?reloaded=true")
  // }

  // useIsomorphicEffect(() => {
  //   const getCachesClient = async () => {
  //     const cachesData = await caches?.keys()
  //     if (cachesData) setCacheClient(cachesData)
  //   }
  //   getCachesClient()
  // }, [])

  // useIsomorphicEffect(() => {
  //   if (cacheClient?.length > 0) clearCacheClient()
  // }, [cacheClient])

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result: any) => {
    const { source, destination } = result
    if (!destination) {
      return
    }
    const items = reorder(form, source.index, destination.index)
    setForm(items)
  }

  const generateJson = async () => {
    const json = generateJSON(
      structureForm,
      isCreated,
      pageId,
      formId,
      nameForm,
      skillIdForm,
      tabIdForm,
      aliasFormResult,
      form
    )
    console.log(json)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>

        <Helmet>
          <title>Tech Forms - Criação de Formulário</title>
        </Helmet>

        <div className={style.container}>
          <div className={style.tabSkill}>
            <div className={style.inputsHeader}>

              <div className={style.containerRowObject}>
                {/* <h5 className={style.h5hidden}>Nome</h5> */}
                <div className={style.rowObjects}>
                  <p className={style.titles}>Nome</p>
                  <InputsInfosPages
                    placeholder='Insira o nome do formulário'
                    type='text'
                    value={nameForm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputNameChange(event, setNameForm)
                    }
                    onBlur={(event) => {
                      formatNameAlias(nameForm, setAliasFormResult)
                      setNamePage(nameForm)
                    }}
                  />
                </div>
              </div>

              <div className={style.containerRowObject}>
                {/* <h5 className={`${style.h5hidden} ${!skillName ? style.h5hiddenClear : ''
                  }`}>{skillName}</h5> */}
                <div className={style.rowObjects}>
                  <p className={style.titles}>SkillId</p>
                  <InputSkill
                    placeholder='Insira a skillId'
                    type='text'
                    skillName={skillName}
                    value={skillIdForm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputSkillChange(event, setSkillIdForm)
                    }
                    onBlur={() => getSkillId(skillIdForm, authorization, ambient, setSkillName)}
                  />
                </div>
              </div>

              <div className={style.containerRowObject}>
                {/* <h5 className={style.h5hidden}>Alias</h5> */}
                <div className={style.rowObjects}>
                  <p className={style.titles}>Alias</p>
                  <InputsInfosPages
                    placeholder='Insira o alias'
                    type='text'
                    value={aliasFormResult}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputAliasChange(event, setAliasFormResult)
                    }
                  />
                </div>
              </div>

              <div className={style.containerRowObject}>
                {/* <h5 className={`${style.h5hidden} ${!tabName ? style.h5hiddenClear : ''
                  }`}>{tabName}</h5> */}
                <div className={style.rowObjects}>
                  <p className={style.titles}>TabId</p>
                  <InputTab
                    placeholder='Insira a tabId'
                    type='text'
                    tabName={tabName}
                    value={tabIdForm}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleInputTabChange(event, setTabIdForm)
                    }
                    onBlur={() => getTabId(tabIdForm, authorization, ambient, setTabName)}
                  />
                </div>
              </div>

            </div>

            <div className={style.topContainer}>

              <ButtonAddGroup onClick={() => handleAddGroup(setForm, setButtonSave)} />

              <ButtonAddFieldOutsideGroup onClick={() => handleAddFieldOutsideGroup(setForm, setButtonSave)} />

              <ButtonAddAttachmentsFieldOutsideGroup onClick={() => handleAddAttachmentsFieldOutsideGroup(setForm, setButtonSave)} />

              <SelectFieldPersonalDetails
                value={addFieldPersonalDetails}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const newValue = event.target.value
                  setAddFieldPersonalDetails(newValue)
                  handleAddPersonalDetailFieldOutsideGroup(
                    form,
                    setForm,
                    newValue,
                    setAddFieldPersonalDetails,
                    setButtonSave
                  )
                }}
              />

              {import.meta.env.MODE !== 'production' && (
                <ButtonGenerateJson onClick={generateJson} />
              )}

              <ButtonSaveForm
                loading={loadingButtonSave}
                buttonSave={buttonSave}
                onClick={() =>
                  saveForm(
                    setLoadingButtonSave,
                    generateJSON(
                      structureForm,
                      isCreated,
                      pageId,
                      formId,
                      nameForm,
                      skillIdForm,
                      tabIdForm,
                      aliasFormResult,
                      form
                    ),
                    authorization,
                    authorizationRA,
                    setButtonPublish,
                    setButtonSave,
                    setIdFormSaved,
                    nameForm,
                    skillIdForm,
                    tabIdForm,
                    aliasFormResult,
                    ambient,
                    setTabName,
                    setSkillName
                  )
                } />

              <ButtonPublishForm
                loading={loadingButtonPublish}
                buttonPublish={buttonPublish}
                onClick={() => publishForm(
                  setExpandedPage,
                  setShowTopPageContainer,
                  setNamePage,
                  setPageId,
                  setIsData,
                  setLoadingButtonPublish,
                  idFormSaved,
                  setSearch,
                  setFormId,
                  setNameForm,
                  setSkillIdForm,
                  setTabIdForm,
                  setItemId,
                  setGroups,
                  setFields,
                  setShowTopContainer,
                  setButtonSave,
                  setButtonPublish,
                  clientId,
                  apiGetFormsAndLogin,
                  authorizationRA,
                  aliasFormResult,
                  setAliasFormResult,
                  setForm,
                  checkTheFunction,
                  ambient,
                  setTabName,
                  setSkillName,
                  authorization
                )}
              />

            </div>
          </div>

          <FormFields
            form={structureForm.children}
            setForm={setForm}
            groups={groups}
            expandedGroups={expandedGroups}
            setExpandedGroups={setExpandedGroups}
            handleCopyGroup={handleCopyGroup}
            handleRemoveGroup={handleRemoveGroup}
            handleAddField={handleAddField}
            handleAddFieldAttachments={handleAddFieldAttachments}
            handleAddPersonalDetailField={handleAddPersonalDetailField}
            handleRemoveField={handleRemoveField}
            handleRemoveFieldInGroup={handleRemoveFieldInGroup}
            toggleGroupVisibility={toggleGroupVisibility}
            setAddFieldPersonalDetails={setAddFieldPersonalDetails}
            addFieldPersonalDetails={addFieldPersonalDetails}
            pageId={pageId}
            setPageId={setPageId}
            namePage={namePage}
            setNamePage={setNamePage}
            isCreated={isCreated}
            setIsCreated={setIsCreated}
            expandedPage={expandedPage}
            setExpandedPage={setExpandedPage}
            showTopPageContainer={showTopPageContainer}
            setButtonSave={setButtonSave}
            authorization={authorization}
            clientId={clientId}
            ambient={ambient}
            formId={formId}
          />

        </div>
      </div>
    </DragDropContext >
  )
}
